import format from 'date-fns/format';

import { DateOptions } from './types';

import parseDate from './parseDate';

/**
 * formatDate implements the canonical formatting of dates according to the design system
 * https://latitude.anchoragedigital.design/latest/content/table-of-contents/numbers-s57dcHvv
 * @param unixNano unix nanosecond representation of the date as a string or number
 */
// @deprecated - use the one in @features/utils
export function formatDate(
  unixNano?: string | number | null,
  dateOptions?: DateOptions,
) {
  if (!unixNano || String(unixNano).length < 1) {
    return '-';
  }

  try {
    return format(parseDate(unixNano, dateOptions), 'MMM d, y');
  } catch (error) {
    console.error('formatDate error', unixNano, error);
    return '-';
  }
}

/**
 * formatMonth implements the canonical formatting of months according to the design system
 * https://latitude.anchoragedigital.design/latest/content/table-of-contents/numbers-s57dcHvv
 * @param unixNano unix nanosecond representation of the month as a string or number
 */
// @deprecated - use the one in @features/utils
export function formatMonth(
  unixNano: string | number,
  dateOptions?: DateOptions,
) {
  try {
    return format(parseDate(unixNano, dateOptions), 'MMM y');
  } catch (error) {
    console.error('formatMonth error', unixNano, error);
    return '';
  }
}

/**
 * formatDateTime implements the canonical formatting of date-times according to the design system
 * https://latitude.anchoragedigital.design/latest/content/table-of-contents/numbers-s57dcHvv
 * @param unixNano unix nanosecond representation of the date-time as a string or number
 */
// @deprecated - use the one in @features/utils
export function formatDateTime(
  unixNano: string | number,
  dateOptions?: DateOptions,
) {
  try {
    // WARNING: the designs require `MMM d, y, h:mmaaa`, but we are temporarily using the format without a comma to make the initial migration to the standard easier
    return format(parseDate(unixNano, dateOptions), 'MMM d, y, h:mmaaa');
  } catch (error) {
    console.error('formatDateTime error', unixNano, error);
    return '';
  }
}
